import { useEffect, useState } from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Grid,
  IconButton,
  TextField,
} from "../../../components";
import { value } from "../../../helpers";
import { formatTelefone } from "../../../utils";
import AdicionarPagamentoDialog from "./dialog/pagamento/adicionar-pagamento-dialog.component";
import { AcrescimoDialog, DescontoDialog, ListaPagamentosVenda } from ".";
import { getListaPagamentoPadrao } from "../pdv";

const PdvClienteView = ({
  venda,
  setVenda,
  setOperacaoFiscal,
  operacaoFiscalList,
  meioPagamentoList,
  condicaoPagamentoList,
  vendedorList,
  clienteSelecionado,
  setClienteSelecionado,
  setOpenDialogClienteAvulso,
  setOpenDialogInformacaoComplementar,
  configuracoes,
  onChangeAutocomplete,
  inputErros,
  setInputErro,
  openDialogPagamentos,
  setOpenDialogPagamentos,
  listaPagamentosVenda,
  setListaPagamentosVenda,
  listaProdutosVenda,
  setListaProdutosVenda,
  openDialogListaPagamentos,
  setOpenDialogListaPagamentos,
  pagamentoSelecionado,
  setPagamentoSelecionado,
  condicaoSelecionada,
  setCondicaoSelecionada,
  processarPagamentos,
  atalhos,
  setAtalhos,
  vendedorObrigatorio,
  colaboradorVendedor,
}) => {
  const [openDialogDesconto, setOpenDialogDesconto] = useState(false);
  const [openDialogAcrescimo, setOpenDialogAcrescimo] = useState(false);
  const [listaPagamentoPadrao, setListaPagamentoPadrao] = useState([]);
  function handleOpenDescontoDialog() {
    if (listaProdutosVenda?.length) {
      setOpenDialogDesconto(true);
    }
  }

  function handleOpenAcrescimoDialog() {
    if (listaProdutosVenda?.length) {
      setOpenDialogAcrescimo(true);
    }
  }

  const onChangeAutocompleteOperacaoFiscal = (newValue) => {
    onChangeAutocomplete("operacaoFiscalId", newValue);
    setOperacaoFiscal(newValue);
  };

  useEffect(() => {
    const listaPagamentos = getListaPagamentoPadrao(
      configuracoes,
      meioPagamentoList
    );
    setListaPagamentoPadrao(listaPagamentos);
    const atalhosPagamentos = [];
    listaPagamentos.forEach((pagamento, index) => {
      atalhosPagamentos.push({
        atalho: `Ctrl + Alt + ${index + 1}`,
        nome: pagamento.descricao,
      });
    });
    if (!atalhos.includes(atalhosPagamentos)) {
      setAtalhos((prevState) => [...prevState, ...atalhosPagamentos]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuracoes, meioPagamentoList]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Autocomplete
            required
            id="operacaoFiscalId"
            name="operacaoFiscalId"
            options={operacaoFiscalList}
            noOptionsText="Sem opções"
            autoHighlight
            disableClearable
            getOptionLabel={(option) =>
              option.descricao ? option.descricao : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Operação Fiscal"
                margin="normal"
                error={inputErros.get("operacaoFiscalId")}
                required
              />
            )}
            onChange={(_, newValue) => {
              onChangeAutocompleteOperacaoFiscal(newValue);
            }}
            value={value.autoComplete(
              operacaoFiscalList,
              venda.operacaoFiscalId
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            id="vendedorId"
            name="vendedorId"
            disabled={!venda?.operacaoFiscalId || colaboradorVendedor}
            options={vendedorList}
            autoHighlight
            getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                label="Vendedor"
                variant={"outlined"}
                required={vendedorObrigatorio}
                error={inputErros.get("vendedorId")}
              />
            )}
            onChange={(_, newValue) => {
              setInputErro([]);
              onChangeAutocomplete("vendedorId", newValue);
            }}
            value={value.autoComplete(vendedorList, venda?.vendedorId)}
          />
        </Grid>
        <Grid item xs={12}>
          <hr className="mt-1 mb-4" />
          <Grid container xs={12}>
            <Grid item xs={6}>
              <span>Cliente:</span>
              {clienteSelecionado ? (
                <>
                  <div className="fw-bold fw-bolder">
                    {clienteSelecionado?.nomeRazaoSocial}
                  </div>
                  <div className="fw-bold fw-bolder">
                    CPF/CNPJ:{" "}
                    <span>
                      {clienteSelecionado?.cnpjCpf || "000.000.000-00"}
                    </span>
                  </div>
                </>
              ) : (
                <div className="fw-bold fw-bolder">SEM CLIENTE INFORMADO</div>
              )}
            </Grid>
            <Grid item xs={5}>
              {clienteSelecionado && clienteSelecionado?.telefone && (
                <span className="fw-bold fw-bolder">
                  TELEFONE: {formatTelefone(clienteSelecionado?.telefone) || ""}
                </span>
              )}
            </Grid>
            <Grid item xs={1} alignContent="center">
              {clienteSelecionado && (
                <IconButton
                  variant="contained"
                  color="padrao"
                  size="small"
                  onClick={() => setClienteSelecionado(null)}
                >
                  <i
                    className="ph-fill ph-x-circle"
                    style={{ fontSize: 30 }}
                  ></i>
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <hr className="mt-1 mb-3" />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                id="identificar-cliente-pdv-button"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  p: "0.5rem 2rem",
                  fontSize: 12,
                  boxShadow: 0,
                  fontWeight: 800,
                }}
                onClick={() => setOpenDialogClienteAvulso(true)}
              >
                IDENTIFICAR CLIENTE
                <i
                  className="ph-fill ph-users"
                  style={{
                    color: "#fff",
                    fontSize: 18,
                    paddingLeft: 10,
                  }}
                ></i>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                id="informacao-complementar-pdv-button"
                variant="contained"
                color="padrao"
                fullWidth
                sx={{
                  p: "0.5rem 2rem",
                  fontSize: 12,
                  boxShadow: 0,
                  fontWeight: 800,
                }}
                onClick={() => setOpenDialogInformacaoComplementar(true)}
              >
                INFORMAÇÃO COMPLEMENTAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <hr className="mt-2 mb-4" />
          <Grid
            item
            xs={12}
            className="d-flex justify-content-between align-items-center"
          >
            <div className="d-flex gap-1">
              <Badge
                badgeContent={listaPagamentosVenda?.length}
                color="primary"
              >
                <Button
                  id="pagamentos-pdv-button"
                  variant="contained"
                  color="padrao"
                  sx={{
                    p: "0.5rem 2rem",
                    ml: "0.5em",
                    fontSize: 12,
                    boxShadow: 0,
                    fontWeight: 800,
                  }}
                  onClick={() => setOpenDialogListaPagamentos(true)}
                >
                  <i
                    className="ph-fill ph-wallet"
                    style={{
                      fontSize: 18,
                      paddingRight: 10,
                    }}
                  ></i>
                  Pagamentos
                </Button>
              </Badge>
            </div>
            <div className="d-flex gap-1">
              <Button
                id="desconto-pdv-button"
                variant="contained"
                color="padrao"
                sx={{
                  p: "0.5rem 2rem",
                  ml: "0.5em",
                  fontSize: 12,
                  boxShadow: 0,
                  fontWeight: 800,
                }}
                onClick={handleOpenDescontoDialog}
              >
                <i
                  className="ph-fill ph-minus-circle"
                  style={{
                    fontSize: 18,
                    paddingRight: 10,
                  }}
                ></i>
                Desconto
              </Button>
              <Button
                id="acrescimo-pdv-button"
                variant="contained"
                color="padrao"
                sx={{
                  p: "0.5rem 2rem",
                  ml: "0.5em",
                  fontSize: 12,
                  boxShadow: 0,
                  fontWeight: 800,
                }}
                onClick={handleOpenAcrescimoDialog}
              >
                <i
                  className="ph-fill ph-plus-circle"
                  style={{
                    fontSize: 18,
                    paddingRight: 10,
                  }}
                ></i>
                Acréscimo
              </Button>
            </div>
          </Grid>
          <Grid container spacing={2} className="mt-2">
            {listaPagamentoPadrao.map(
              (meioPagamento, index) =>
                index < 5 && (
                  <Grid item xs={6} key={meioPagamento.descricao}>
                    <Button
                      id={`meio-pagamento-${index + 1}`}
                      onClick={() => processarPagamentos(meioPagamento)}
                      variant="outlined"
                      color="primary"
                      fullWidth
                      sx={{
                        p: "1.2rem 3rem",
                        ml: "0.5em",
                        fontSize: 12,
                        boxShadow: 0,
                        fontWeight: 800,
                      }}
                    >
                      {meioPagamento.descricao}
                    </Button>
                  </Grid>
                )
            )}
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                sx={{
                  p: "1.2rem 3rem",
                  ml: "0.5em",
                  fontSize: 12,
                  boxShadow: 0,
                  fontWeight: 800,
                }}
                onClick={() => processarPagamentos()}
              >
                Outros
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AdicionarPagamentoDialog
        open={openDialogPagamentos}
        setOpen={setOpenDialogPagamentos}
        pagamentoSelecionado={pagamentoSelecionado}
        setPagamentoSelecionado={setPagamentoSelecionado}
        meioPagamentoList={meioPagamentoList}
        condicaoPagamentoList={condicaoPagamentoList}
        listaPagamentosVenda={listaPagamentosVenda}
        setListaPagamentosVenda={setListaPagamentosVenda}
        venda={venda}
        listaProdutosVenda={listaProdutosVenda}
        condicaoSelecionada={condicaoSelecionada}
        setCondicaoSelecionada={setCondicaoSelecionada}
      />
      <ListaPagamentosVenda
        open={openDialogListaPagamentos}
        setOpen={setOpenDialogListaPagamentos}
        meioPagamentoList={meioPagamentoList}
        listaPagamentosVenda={listaPagamentosVenda}
        setListaPagamentosVenda={setListaPagamentosVenda}
      />
      <DescontoDialog
        open={openDialogDesconto}
        setOpen={setOpenDialogDesconto}
        venda={venda}
        listaProdutosVenda={listaProdutosVenda}
        setListaProdutosVenda={setListaProdutosVenda}
        setListaPagamentosVenda={setListaPagamentosVenda}
      />
      <AcrescimoDialog
        open={openDialogAcrescimo}
        setOpen={setOpenDialogAcrescimo}
        venda={venda}
        listaProdutosVenda={listaProdutosVenda}
        setListaProdutosVenda={setListaProdutosVenda}
        setListaPagamentosVenda={setListaPagamentosVenda}
      />
    </>
  );
};

export default PdvClienteView;
